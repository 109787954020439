.Archive {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4.5rem;
    width: 100%;

    .list {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding: 0;
        margin: 0;
        transition: opacity 1s ease, transform 1s ease;

        .item {
            &.current {
                .button {
                    background-color: var(--color-red);
                    color: var(--color-white);
                }
            }
        }

        .button {
            margin: 1rem 0.5rem;
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    &.animate-page-in-state {
        .Text {
            .text-content {
                opacity: 0;
                transform: translateY(20vh);
            }
        }

        .list {
            opacity: 0;
            transform: translateY(20vh);
        }
    }
}

@media screen and (orientation: landscape) {
    .Archive {
        height: 100%;
        flex-wrap: wrap;
        padding-top: 0;

        .list {
            height: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 0 0 5vw;
        }
    }
}