.Loader {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 5rem;
    height: 5rem;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
    opacity: 0;
    z-index: 4;

    &.active {
        opacity: 1;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(270deg);
        }
    }

    @keyframes turn {
        0% {
            stroke-dashoffset: 180;
        }

        50% {
            stroke-dashoffset: 45;
            transform: rotate(135deg);
        }

        100% {
            stroke-dashoffset: 180;
            transform: rotate(450deg);
        }
    }

    .spinner {
        width: 100%;
        height: 100%;
        animation: rotation 1.35s linear infinite;
        stroke: var(--color-red);

        &.spinner-white {
            stroke: var(--color-white);
        }
    }

    .circle {
        stroke-dasharray: 180;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation: turn 1.35s ease-in-out infinite;
    }
}