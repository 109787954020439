@import '../../styling/variables.scss';

.HorizontalScroll {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

// @media screen and (min-width: $width-horizontal-scroll) {
@media screen and (orientation: landscape) {
    .HorizontalScroll {
        overflow-x: auto;
        overflow-y: hidden;
    }
}