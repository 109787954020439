@import '../../styling/variables.scss';

.SelfPortraitsModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);
    z-index: 5;
    backdrop-filter: blur(0.5rem);
    // transition: background-color 0.25s ease;
    transition: backdrop-filter 0.25s ease, background-color 0.5s ease 0.25s;
    
    &.animate-element-in-state {
        background-color: transparent;
        backdrop-filter: blur(0);

        .modal-content {
            transform: translateY(10vh);
            opacity: 0;

            .asset {
                opacity: 0;
            }
        }
    }

    &.animate-element-out-state {
        background-color: transparent;
        backdrop-filter: blur(0);
        transition: backdrop-filter 0.25s ease 0.5s, background-color 0.5s ease;

        .modal-content {
            transform: translateY(10vh);
            opacity: 0;
            transition: opacity 0.5s ease, transform 0.5s ease;
        }
    }


    .modal-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5vh 2vw;
        transition: opacity 0.5s ease 0.25s, transform 0.5s ease 0.25s;
        
        .asset {
            max-width: 100%;
            max-height: 100%;
            height: calc(100% - 6rem);
            flex: 1 1;
            object-fit: contain;
            object-position: center;
            transition: opacity 0.5s ease 0.75s;
        }

        .description {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-shrink: 0;
            margin: 2vh 0 0;
            height: 6rem;
        
            .push-down {
                margin-bottom: 2vh;
            }
        }

        .close {
            background-color: transparent;
            border: none;
            width: 2rem;
            height: 2rem;
            padding: 0;
            cursor: none;
            outline: none;

            &:active {
                .close-icon {
                    // border-color: transparent;
                    filter: drop-shadow(0 1.33px 0.5rem rgba(0,0,0,0.2));
                    #CloseCircleRed {
                        transform: scale(0);
                    }
                    #CloseCross {
                        stroke: var(--color-black);
                    }
                }
            }
    
            .close-icon {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                cursor: none;

                #CloseCircleRed {
                    transform: scale(1);
                    transform-origin: center;
                    transition: transform 0.25s ease;
                }
                #CloseCross {
                    transition: stroke 0.25s ease;
                    stroke: var(--color-white);
                }
            }
        }
    }
}

@media (hover: hover) {
    .SelfPortraitsModal {
        .modal-content {
            .description {
                .medium {
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .nolink {
                  &:hover {
                    text-decoration: none;
                }
                }
            }

            .close {
                &:hover {
                    .close-icon {
                        filter: drop-shadow(0 1.33px 0.5rem rgba(0,0,0,0.2));
                        #CloseCircleRed {
                            transform: scale(0);
                        }
                        #CloseCross {
                            stroke: var(--color-black);
                        }
                    }
                }
            }
        }
    }
}