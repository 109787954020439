.button {
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: var(--color-white);
    color: var(--color-black);
    border: 1px solid var(--color-greylight);
    border-radius: 3rem;
    padding: 0.75rem 1rem 0.65rem;
    margin-bottom: 0.5rem;
    outline: none;

    &:active,
    &.active {
        color: var(--color-white);
        background-color: var(--color-red);
        box-shadow: 0 1.33px 0.5rem rgba(0,0,0,0.2);
    }
}

.grid-button {
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    border: 1px solid var(--color-greylight);
    border-radius: 3rem;
    margin-top: 12px;
    margin-bottom: 0;
    padding: 12px 0 7px;
    width: 135px;
    background-color: var(--color-white);
    color: var(--color-red);
    outline: none;

    &.button-red {
        background-color: var(--color-red);
        color: var(--color-white);
    }

    &:active {
        box-shadow: 0 1.33px 0.5rem rgba(0,0,0,0.2);
    }
}

@media (hover: hover) {
    .button {
        transition: background-color 0.15s ease, color 0.15s ease;
        &:hover {
            color: var(--color-white);
            background-color: var(--color-red);
        }
    }

    .grid-button {
        &:hover {
            box-shadow: 0 1.33px 0.5rem rgba(0,0,0,0.2);
        }
    }
}

@media screen and (orientation: landscape) {
    .button {
        font-size: 1.955vh;
        line-height: 2.5vh;
        padding: 1.5vh 2vw 1.35vh;
        margin-bottom: 2vh;
    }
}