@font-face {
    font-family: "BentonSans";
    src: url("../assets/fonts/BentonSans-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "BentonSans";
    src: url("../assets/fonts/BentonSans-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "BentonSans";
    src: url("../assets/fonts/BentonSans-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

body {
    font-family: "BentonSans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-black);
}

html,
button,
input,
select,
option,
textarea {
    font-family: "BentonSans";
}

h1, h2, h3, p {
    margin: 0;
    font-weight: 400;
}

.title {
    font-size: 2.75em;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.text {
    font-size: 0.925em;
    font-weight: 700;
    line-height: 1.375em;
    white-space: pre-line;
}



/* Colours used within the Text module */
.red {
    color: var(--color-red);
}
.black {
    color: var(--color-black);
}
.grey {
    color: var(--color-grey);
}
.white {
    color: var(--color-white);
}

/* Alignments used within the Text module */
.left {
    text-align: left;
    display: block;
}
.right {
    text-align: right;
    display: block;
}
.center {
    text-align: center;
    display: block;
}

/* Font sizes used within the Text Module */
.small {
    font-size: 0.85rem;
    line-height: 1.1rem;
}
.regular {
    font-size: 1rem;
    line-height: 1.275rem;
}
.medium {
    font-size: 1.325rem;
    line-height: 1.5rem;
}
.large {
    font-size: 1.75rem;
    line-height: 2rem;
}
.xl {
    font-size: 2.25rem;
    line-height: 2.6rem;
}
.xxl {
    font-size: 3.15rem;
    line-height: 3.7rem;
}
.xxxl {
    font-size: 3.15rem;
    line-height: 3.7rem;
}
.xxxxl {
    font-size: 6.8rem;
    line-height: 6.875rem;
}

/* Font weights and styles used within the Text Module */
.semi-bold {
    font-weight: 500;
}
.bold {
    font-weight: bold;
}
.italic {
    font-style: italic;
}

/* Move text in a direction */
.pad-top {
    display: block;
    margin-top: 5vw;
}
.pad-left {
    display: block;
    margin-left: 15vw;
}
.pad-bottom {
    display: block;
    margin-bottom: 5vw;
}
.pad-right {
    display: block;
    margin-right: 15vw;
}

.mobile-word-break {
    word-break: break-all;
}

// Standard Link Theming
.theme-red,
.theme-black {
    a {
        color: var(--color-white);
    }
}
.theme-white
.theme-grey {
    a {
        color: var(--color-white);
    }
}
.theme-beige {
    a {
        color: var(--color-red);
    }
}

// @media screen and (min-width: $width-horizontal-scroll) {
@media screen and (orientation: landscape) {
    .small {
        font-size: 1.675vh;
        line-height: 2.14vh;
    }
    .regular {
        font-size: 1.955vh;
        line-height: 2.5vh;
    }
    .medium {
        font-size: 3.235vh;
        line-height: 4vh;
    }
    .large {
        font-size: 5.7vh;
        line-height: 6vh;
    }
    .xl {
        font-size: 8vh;
        line-height: 8.25vh;
    }
    .xxl {
        font-size: 10.4vh;
        line-height: 12.5vh;
    }
    .xxxl {
        font-size: 15.3vh;
        line-height: 15.75vh;
    }
    .xxxxl {
        font-size: 24.75vh;
        line-height: 25vh;
    }

    .pad-top {
        display: block;
        margin-top: 5vh;
    }
    .pad-left {
        display: block;
        margin-left: 25vh;
    }
    .pad-bottom {
        display: block;
        margin-bottom: 5vh;
    }
    .pad-right {
        display: block;
        margin-right: 25vh;
    }

    .mobile-word-break {
        word-break: initial;
    }
}