.Menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: var(--color-black);
    clip-path: circle(100% at 50% 50%);
    transition: clip-path 500ms ease;
    will-change: clip-path;
    cursor: none;

    &.start {
        clip-path: circle(1rem at calc(100% - 1.75rem) 1.75rem);
        transition: clip-path 350ms ease;
    }

    .content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        min-height: 100%;
        padding: 6.75rem 1rem 1rem;
    }

    .menu-logo {
        width: 4.5rem;
        position: fixed;
        left: 0.75rem;
        top: 0.75rem;
    }

    .close {
        position: fixed;
        right: 0.75rem;
        top: 0.75rem;
        background-color: transparent;
        border: none;
        width: 2rem;
        height: 2rem;
        padding: 0;
        cursor: none;
        outline: none;
        z-index: 9;

        &:active {
            .close-icon {
                filter: drop-shadow(0 1.33px 0.5rem rgba(0,0,0,0.2));
                #CloseCircleRed {
                    transform: scale(1);
                }
                #CloseCross {
                    stroke: var(--color-white);
                }
            }
        }

        .close-icon {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;

            #CloseCircleRed {
                transform: scale(0);
                transform-origin: center;
                transition: transform 0.25s ease;
            }
            #CloseCross {
                transition: stroke 0.25s ease;
            }
        }
    }

    .column {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .navigation {
        width: 100%;
        padding-left: 0.25rem;
        .list {
            margin: 0;
            padding: 0;
            list-style-type: none;
            .item {
                width: 100%;
                margin-bottom: 1rem;
                position: relative;
                .number {
                    display: none;
                    transition: opacity 0.5s ease, transform 0.5s ease;
                }

                .link {
                    display: block;
                    text-decoration: none;
                    font-size: 1.875rem;
                    font-weight: 700;
                    color: var(--color-white);
                    transition: transform 0.5s ease;
                    transform-origin: center center;

                    &.small {
                        font-size: 1.35rem;
                        margin-top: 1.2rem;
                    }

                    &.archive {
                        font-size: 1.15rem;
                        color: var(--color-grey);
                    }
                }

                &.archive {
                    margin-bottom: 0;
                }

            }
        }

    }

    .contact {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 1.6rem;

        .social {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;

            .list {
                margin: 0;
                padding: 0;
                list-style-type: none;

                .item {
                    width: 100%;
                    margin-bottom: 0.725rem;

                    .link {
                        display: block;
                        text-decoration: none;
                        font-size: 0.925rem;
                        color: #969696;
                        transition: transform 0.5s ease, color 0.5s ease;
                        transform-origin: center center;
                    }
                }
            }
        }

        .address {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
            padding-left: 0.25rem;

            .heading {
                font-size: 0.775rem;
                margin: 0.35rem 0 0;
                color: #969696;
            }

            .line {
                font-size: 0.775rem;
                white-space: pre-wrap;
                letter-spacing: -0.025rem;
                margin-top: 0.5rem;
                line-height: 1.65rem;
                color: #969696;
                &:first-of-type {
                    margin-bottom: 0.65rem;
                }
            }
        }
    }

    .support {
        width: 55%;
        margin-top: 2rem;
        .image {
            width: 100%;
            display: block;
            filter: invert(1);
        }
    }

    .copyright {
        margin-top: 1.5rem;
        font-size: 0.6375rem;
        line-height: 0.875rem;
        color: #969696;
    }

    .shows {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .link {
            font-size: 1.35rem;
            margin-top: 0;
            margin-right: 1rem;
            text-decoration: none;
            font-weight: 700;
            color: var(--color-grey);
            transition: transform 0.5s ease;
            transform-origin: center center;
        }
    }

}

@media (hover: hover) {
    .Menu {
        .close {
            &:hover {
                .close-icon {
                    #CloseCircleRed {
                        transform: scale(1);
                    }
                    #CloseCross {
                        stroke: var(--color-white);
                    }
                }
            }
        }
        .navigation .list .item {
            &:hover {
                .number {
                    opacity: 1;
                    transform: translateX(0);
                }
                .link {
                    transform: skewX(-15deg);

                    &.no-hover {
                        transform: skewX(0deg);
                    }

                }
                
            }
        }
        .contact .social .list .item .link {
            &:hover {
                transform: skewX(-15deg);
                color: var(--color-white);
            }
        }
    }

    .shows .link {
        &:hover {
            transform: skewX(-15deg);
        }
    }
}

@media screen and (min-width: 850px) {
    .Menu {
        
        .content { 
            padding-left: 26%;
            display: flex;
        }

        .column {
            &.column-left {
                width: 59.5%;
            }
            
            &.column-right {
                width: 40.5%;
            }
        }

        .navigation .list .item {

            .number {
                display: block;
                position: absolute;
                left: -2.5em;
                bottom: 0.35em;
                color: #e01221;
                opacity: 0;
                transform: translateX(-1em);
            }

            .link {
                &.small {
                    margin-top: 2rem;
                }
            }
        }

        .contact {
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 0.7rem;

            .social .list .item {
                margin-bottom: 0.625rem;
                .link {
                    font-size: 0.7rem;
                }
            }

            .address {
                margin-top: 1.6rem;
                .heading {
                    font-size: 0.725rem;
                }
                .line {
                    font-size: 0.725rem;
                    line-height: 1rem;
                }
            }
        }

        .support {
            width: 62%;
            margin-top: 2rem;
        }

        .copyright {
            font-size: 0.5rem;
            padding-right: 10%;
        }
    }

}

@media screen and (orientation: landscape) {
    .Menu {
        &.start {
            clip-path: circle(2.375vh at calc(100% - 4.175vh) 4.175vh);
        }
        
        .menu-logo {
            height: 6vh;
            width: auto;
            left: 1.8vh;
            top: 1.8vh;
        }
        
        .close {
            right: 1.8vh;
            top: 1.8vh;
            width: 4.75vh;
            height: 4.75vh;
        }
    }
}