@import "../../../styling/variables.scss";

.Video {
    margin: 0 0 5vh 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 1s ease, opacity 1s ease;

    .asset {
        position: relative;

        iframe {
            pointer-events: none;
        }
    }

    .wrapper {
        position: relative;
        user-select: none;

        &:fullscreen {
            .asset {
                iframe {
                    width: 100vw !important;
                    height: 100vh !important;
                }
            }
            .playback {
                * {
                    cursor: auto;
                }
            }
        }
    }

    .playback {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: var(--color-black);
        height: 1.1rem;
        touch-action: none;
        user-select: none;

        .progress {
            flex: 1;
            margin: 0 0.2rem;
            height: 100%;
            display: flex;
            align-items: center;
            
            .progress-bar {
                height: 0.125rem;
                width: 100%;
                background-color: var(--color-grey);
                border-radius: 0.5rem;

                .progress-bar-active {
                    width: 100%;
                    height: 0.125rem;
                    transform-origin: left;
                    background-color: var(--color-red);
                    border-radius: 0.5rem;
                    transform: scaleX(0);
                }
            }
        }

        .counter {
            font-size: 0.6rem;
            margin: 0 0.25rem;
            color: var(--color-white);
            width: 4.3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.05rem 0 0;
            user-select: none;
        }

        .fullscreen {
            width: 0.5rem;
            height: 0.5rem;
            padding: 0;
            margin: 0 0.25rem;
            display: flex;
            align-items: center;
            flex-shrink: 0;

            .fullscreen-icon {
                width: 100%;
                height: 100%;
            }
        }
    }

    .fullscreen-close {
        position: fixed;
        right: 0.75rem;
        top: 0.75rem;
        background-color: transparent;
        border: none;
        width: 2rem;
        height: 2rem;
        padding: 0;
        cursor: none;
        outline: none;
        z-index: 9;
    
        &:active {
            .close-icon {
                filter: drop-shadow(0 1.33px 0.5rem rgba(0,0,0,0.2));
                #CloseCircleRed {
                    transform: scale(1);
                }
                #CloseCross {
                    stroke: var(--color-white);
                }
            }
        }

        .close-icon {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;

            #CloseCircleRed {
                transform: scale(0);
                transform-origin: center;
                transition: transform 0.25s ease;
            }
            #CloseCross {
                transition: stroke 0.25s ease;
            }
        }
    }

    .adjacent {
        margin-top: 2vh;
    }
}

.animate-in-state {
    .Video {
        transform: translateY(20vh);
        opacity: 0;
    }
}

@media (hover: hover) {
    .Video {
        .fullscreen-close {
            &:hover {
                .close-icon {
                    #CloseCircleRed {
                        transform: scale(1);
                    }
                    #CloseCross {
                        stroke: var(--color-white);
                    }
                }
            }
        }
    }
}

@media screen and (orientation: portrait) {
    .LayoutRow {
        > .AnimateElementIn {
            &:last-child {
                .Video {
                    margin-bottom: 0;
                }
            }
        }
    }

    .LayoutColumn {
        > .AnimateElementIn {
            &:last-child {
                .Video {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .Video {
        margin: 0 5vw 0 0;

        &.adjacent-position-right {
            flex-direction: row;
            .adjacent {
                margin-top: 0;
                margin-left: 2vw;
            }
        }

        .adjacent {
            max-width: 14rem;
        }
    }

    .animate-in-state {
        .Video {
            transform: translateX(20vw);
            opacity: 0;
        }
    }

    .LayoutRow {
        > .AnimateElementIn {
            &:last-child {
                .Video {
                    margin-right: 0;
                }
            }
        }
    }

    .LayoutColumn {
        > .AnimateElementIn {
            .Video {
                margin-right: 0;
            }
        }
    }
}