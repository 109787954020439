.LaunchVideo {
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: 0;
    height: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,0);

    &.ready {
        transition: background-color 0.75s;
        background-color: rgba(0,0,0,1);
    }

    .Cursor {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .video {
        flex: 1;
    }

    .Video {
        margin: 0;
    }

    .close {
        position: fixed;
        right: 0.75rem;
        top: 0.75rem;
        background-color: transparent;
        border: none;
        width: 2rem;
        height: 2rem;
        padding: 0;
        cursor: none;
        outline: none;
        z-index: 9;

        &:active {
            .close-icon {
                filter: drop-shadow(0 1.33px 0.5rem rgba(0,0,0,0.2));
                #CloseCircleRed {
                    transform: scale(1);
                }
                #CloseCross {
                    stroke: var(--color-white);
                }
            }
        }

        .close-icon {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;

            #CloseCircleRed {
                transform: scale(0);
                transform-origin: center;
                transition: transform 0.25s ease;
            }
            #CloseCross {
                transition: stroke 0.25s ease;
            }

            &:hover {
                #CloseCircleRed {
                    transform: scale(1);
                }
                // changes the hamburger icon from black to white
                #CloseCross {
                    stroke: var(--color-white);
                }
            }
        }

    }

}