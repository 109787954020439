.AddressListSelector {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.8em;
    margin-right: 0.25em;

    .nav-item {
        background-color: transparent;
        border: none;
        text-transform: uppercase;
        font-size: 1.7vh;
        margin-bottom: 0.5vh;
        outline: none;
        color: #898a92;
        padding: 0 0.1em;
    }

    &.horizontal {
        position: static;
        flex-direction: row;
        padding: 0;
        margin-left: 0.25em;
    }

    &.tutors {
      visibility: hidden;
    }
}