.ColourCrossfade {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: background-color 3s;


    color: rgba(255,255,255,0.3);
    font-size: 33vh;
    line-height: 33vh;
    text-align: right;
    font-weight: bold;

    @media screen and (orientation: portrait) {
        color: rgba(255,255,255,0);
    }
}

