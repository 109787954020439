.LandingAnimaton {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    background-color: var(--color-white);
    z-index: 9999999;

    .content {
        width: 100%;
        height: 100%;
    }
    iframe {
        width: 100vw;
        height: 100vh; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        position: absolute;
        top: 0;
        left: 0;
    }
}
 