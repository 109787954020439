.Cursor {
    width: 100%;
    height: 100%;
    position: relative;

    * {
        cursor: none;
    }

    .cursor-icon {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-50%, -50%);
        width: 1rem;
        height: 1rem;
        background-color: var(--color-red);
        border-radius: 100%;
        pointer-events: none;
        z-index: 5;
        opacity: 0.9;

        &.hide {
            opacity: 0;
        }

        &.enlarge {
            width: 2rem;
            height: 2rem;
        }

        &.expanded {
            width: 3rem;
            height: 3rem;
            
            &:before {
                content: '';
                position: absolute;
                left: calc(50% - 0.05rem);
                top: calc(50% - 0.5rem);
                width: 0.1rem;
                height: 1rem;
                background-color: #fff;
            }
            &:after {
                content: '';
                position: absolute;
                left: calc(50% - 0.5rem);
                top: calc(50% - 0.05rem);
                width: 1rem;
                height: 0.1rem;
                background-color: #fff;
            }
        }

        &.cursor-white {
            background-color: var(--color-white);
        }
    }
}