@import "../../../styling/variables.scss";

.Text {
    margin-bottom: 5vh;

    .text-content {
        white-space: pre-wrap;
        font-size: 1rem;
        line-height: 1.275rem;
        transition: opacity 1s ease, transform 1s ease;
    }
}

.animate-in-state {
    .Text {
        .text-content {
            opacity: 0;
            transform: translateY(20vh);
        }
    }
}

@media screen and (orientation: portrait) {

    .Text {
        .text-content {
            span {
                white-space: normal;
            }
        }
    }

    .LayoutRow {
        > .AnimateElementIn {
            &:last-child {
                .Text {
                    margin-bottom: 0;
                }
            }
        }
    }

    .LayoutColumn {
        > .AnimateElementIn {
            &:last-child {
                .Text {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .Text {
        margin-bottom: 0;

        &.set-width-bounds {
            min-width: 14rem;
            max-width: 30rem;
        }

        .text-content {
            white-space: pre-wrap;
            font-size: 1.955vh;
            line-height: 2.5vh;
        }
    }

    .animate-in-state {
        .Text {
            .text-content {
                opacity: 0;
                transform: translateX(20vw);
            }
        }
    }
}