@import "../../styling/variables.scss";

.SelfPortraits {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .dropdown-icon {
        pointer-events: none;
    }
    
    &.animate-page-in-state {
        .section-header {
            transform: translateX(100vw);
            opacity: 0;
        }
        
        .filters,
        .colors {
            transform: translateX(100vw);
            opacity: 0;
        }

        .MasonryGallery {
            transform: translateY(40vh);
            opacity: 0;
        }
    }

    .section-header {
        transition: transform 0.5s ease, opacity 0.5s ease;
    }

    .options {
        width: 100%;
        flex-shrink: 0;
        display: flex;
        padding: 3rem 0 1rem;
        margin-bottom: 1rem;
        background-color: var(--color-white);
        z-index: 2;

        .options-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: var(--color-white);
            padding: 0 0.75rem;
        }

        .filters {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0 0.25rem;
            border-bottom: 1px solid var(--color-greylight);
            transition: transform 0.5s ease, opacity 0.5s ease;

            .selector {
                width: 100%;

                .label .icon {
                    width: 1rem;
                    margin-left: 0.5rem;
                    display: block;
                    position: static;
                    transform: none;
                }
            }
        }

        .colors {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 0.5rem;
            transition: transform 0.5s ease, opacity 0.5s ease;

            .colors-selector {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .colors-select {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    &:not(:last-child) {
                        margin-right: 0.5rem;
                    }

                    .range {
                        flex: 1;
                        min-width: 0;
                        margin-left: 0.25rem;
                    }
                }
            }

            .colors-result {
                width: 100%;
                height: 4px;
            }
        }
    }

    .MasonryGallery {
        transition: transform 0.5s ease, opacity 0.5s ease;
    }

    .section {
        width: 100%;
        height: 100%;
        flex: 1 1;
    }
}

@media screen and (orientation: landscape) {
    .SelfPortraits {
        &.animate-page-in-state {
            .filters {
                transform: translateX(-100vw);
            }
            .options {
                .options-content {
                    border-color: transparent;
                }
            }
        }
        
        .options {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: auto;
            margin-bottom: 0.25rem;
            padding-top: 3.5rem;
            padding-bottom: 0;

            .options-content {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: auto;
                border-top: 1px solid var(--color-greylight);
                transition: border-color 0.5s ease;
            }

            .filters {
                margin-right: 1rem;
                border-bottom: none;
                max-width: 23.5rem;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: 750px) {
    .SelfPortraits {
        .options {
            .colors {
                width: 100%;
                max-width: 36rem;
                flex-direction: row;
                align-items: center;
                padding-top: 0.25rem;

                .colors-label {
                    flex-shrink: 0;
                    margin-right: 1rem;
                }

                .colors-selector {
                    margin-top: 0;
                }

                .colors-result {
                    width: 1.625rem;
                    height: 1.625rem;
                    margin: 0;
                    margin-left: 0.5rem;
                    flex-shrink: 0;
                }
            }
        }
    }
}