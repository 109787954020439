@import "../../styling/variables.scss";

.AddressList {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .group {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .letter {
        font-size: 4.5rem;
        line-height: 5rem;
        text-transform: uppercase;
        white-space: pre-line;
        min-width: 3.5em;

        &.tutors {
            font-size: 1.2rem;
            line-height: 1.75rem;
            margin-top: 0.75rem;
            width: auto;
            font-weight: bold;
        }
    }
    .text {
        margin-bottom: 0.55em;
    }
}

@media screen and (orientation: landscape) {
    .AddressList {
        height: 100%;
        flex-wrap: wrap;
        
        .group {
            margin-top: 0.75vh;
            margin-bottom: 0.25vh;
        }

        .letter {
            display: flex;
            align-items: flex-end;
            margin: 0;
            font-size: 7.5vh;
            line-height: 8vh;

            &.tutors {
                font-size: 2.65vh;
                line-height: 3.5vh;
                margin-right: 3em;
                text-transform: capitalize;
            }
        }

        .text {
            height: 3vh;
            font-size: 1.555vh;
            margin: 0;
            width: 34vh;
        }
    }
}