@import "../../styling/variables.scss";

.MessageWall {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-beige);

    &.animate-page-in-state {
        .content {
            transform: translateY(10vh);
            opacity: 0;
        }
    }

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: transform 0.5s ease 0.25s, opacity 0.5s ease 0.25s;
    }

    .cards {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 10vh 0 5vh;
    
        .card {
            width: calc( 100% - 1rem);
            height: calc( 100vw - 1rem);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0.5rem 0.5rem;
            background-color: #fff;
            border-radius: 0.75rem;
            transition: transform 0.5s ease 0.25s, opacity 0.5s ease 0.25s;
            box-shadow: 0 1rem 1.25rem rgba(218,208,198,0.2);
            padding: 1.25rem 1rem;
            position: relative;
            font-size: calc( 100vw / 18 );

            &.submit-message {
                background-color: var(--color-red);
                justify-content: center;
                padding-left: 1.825rem;
                padding-bottom: 2.5rem;

                .card-content {
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                }

                .card-text {
                    font-size: 0.675em;
                    line-height: 1.2em;
                }
            }

            .emoji {
                display: block;
                width: 1.5625em;
                margin-bottom: 0.2em;
            }

            .card-content {
                margin-top: 0.85em;
                font-size: 1.25em;
                line-height: 1.25em;
                word-break: break-word;
                overflow: hidden;

                &.smaller {
                    line-height: 1.25em;
                }
            }

            .card-name {
                position: absolute;
                bottom: 0.825em;
                left: 1em;
                font-size: 0.9em;
            }
        }
    }

    .error {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@media screen and (min-width: 360px) {
    .MessageWall {
        .cards {
            .card {
                font-size: calc( 50vw / 18 );
                width: calc( 50% - 1rem);
                height: calc( 50vw - 1rem);
                margin: 0.5rem 0.5rem;
            }
        }
    }
}

@media screen and (min-width: 720px) {
    .MessageWall {
        .cards {
            .card {
                font-size: calc( 33vw / 18 );
                width: calc( 33% - 1rem);
                height: calc( 33vw - 1rem);
                margin: 0.5rem 0.5rem;
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .MessageWall {
        .cards {
            .card {
                font-size: calc( 25vw / 18 );
                width: calc( 25% - 1rem);
                height: calc( 25vw - 1rem);
                margin: 0.5rem 0.5rem;
            }
        }
    }
}

@media screen and (min-width: 1360px) {
    .MessageWall {
        .cards {
            .card {
                font-size: calc( 20vw / 18 );
                width: calc( 20% - 1rem);
                height: calc( 20vw - 1rem);
                margin: 0.5rem 0.5rem;
            }
        }
    }
}
