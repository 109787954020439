.section-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5.7rem 1rem 0;
    
    .Text {
        margin-bottom: 0.5rem;

        .push-down {
            margin-bottom: 1rem;
        }
    }
}

@media screen and (orientation: landscape) {
    .SelfPortraits {
        .section-header {
            flex-direction: row;
            align-items: center;
            padding: 15.1vh 21vw 0 14vw;
            
            .left {
                margin-right: 3.8vw;
            }
    
            .push-down {
                margin-bottom: 3vh;
            }
        }
    }
}

@media screen and (min-width: 1080px) and (max-width: 1400px) {
    .SelfPortraits {
        .section-header {
            padding: 15.1vh 3vw 0 3vw;
        }
    }
}