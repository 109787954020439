@import "../../../styling/variables.scss";

.PartnersList {
    display: block;
    width: 100%;
    transition: transform 1s ease, opacity 1s ease;
    margin-bottom: 2rem;

    &.colour-black {
        .link {
            color: var(--color-black);
        }
    }

    &.colour-red {
        .link {
            color: var(--color-red);
        }
    }

    &.colour-white {
        .link {
            color: var(--color-white);
        }
    }

    .group {
        display: inline;
    }
    
    .link {
        text-decoration: none;
        color: var(--color-black);

        &:hover {
            color: var(--color-red);
        }
    }
}

.animate-in-state {
    .PartnersList {
        transform: translateY(20vh);
        opacity: 0;
    }
}

@media (hover: hover) {
    .PartnersList {
        &.hover-black {
            .link {
                &:hover {
                    color: var(--color-black);
                }
            }
        }
        &.hover-red {
            .link {
                &:hover {
                    color: var(--color-red);
                }
            }
        }
    
        &.hover-white {
            .link {
                &:hover {
                    color: var(--color-white);
                }
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .PartnersList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: auto;
        vertical-align: middle;
        margin-bottom: 0;
    }

    .animate-in-state {
        .PartnersList {
            transform: translateX(20vw);
            opacity: 0;
        }
    }
}
