html {
    font-size: 14px;
}

@media screen and (min-width: 340px) {
    html {
        font-size: 16px;
    }
}

@media screen and (min-width: 530px) {
    html {
        font-size: 17px;
    }
}

@media screen and (min-width: 720px) {
    html {
        font-size: 19px;
    }
}

@media screen and (min-width: 1020px) {
    html {
        font-size: 20px;
    }
}

@media screen and (min-width: 1300px) {
    html {
        font-size: 21px;
    }
}

@media screen and (min-width: 1550px) {
    html {
        font-size: 22px;
    }
}

@media screen and (min-width: 1750px) {
    html {
        font-size: 23px;
    }
}

@media screen and (min-width: 1960px) {
    html {
        font-size: 24px;
    }
}