@import '../../styling/variables.scss';

.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
}


.home-container {
    overflow: auto;
    height: 100%;
    width: 100%;
}

.Home {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0rem;
    width: 100%;

    .mainLayout {
        position: relative;
        padding: 7rem 0.75rem 0 0.75rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .Text {
        max-width: 100%;
        margin-left: 0;
    }

    &.animate-page-in-state {
        .Text {
            .text-content {
                opacity: 0;
                transform: translateY(20vh);
            }
        }
        .selector {
            opacity: 0;
            transform: translateY(20vh);
        }
        .show-launch-video {
            opacity: 0;
            transform: translateY(20vh);
        }
    }

    .LayoutColumn {
        &.reduced-pad-bottom {
            padding-bottom: 0vh;
        }
        background-color: transparent;
    }

    .selector {
        transition: opacity 1s ease, transform 1s ease;
    }

    .xxl {
        font-size: 12.4vh;
        line-height: 70%;
    }

    .show-launch-video {
        display: flex;
        align-items: center;
        margin-top: 5rem;
        transition: opacity 1s ease, transform 1s ease;

        .icon {
            margin-right: 1rem;
            width: 2.25rem;
            height: 2.25rem;
            background-color: var(--color-red);
            border-radius: 100%;
            border: none;
            outline: none;
            transition: transform 0.5s ease;

            .play-icon {
                margin-left: 0.3rem;
                margin-top: 0.2rem;
                width: 0.75rem;
                height: 0.75rem;

                #Play {
                    fill: white;
                }
            }
        }

        .label {
            margin-top: 0.25rem;
        }
    }

    .button {
        border: none;
        box-shadow: none;
        font-weight: bold;
        line-height: 100%;
        color: var(--color-white);
        background-color: var(--color-red);
        border:1px solid transparent;

        &:hover {
            color: var(--color-red);
            background-color: var(--color-white);  
            border: 1px solid var(--color-greylight);  
        }
    }
}

@media (hover: hover) {
    .show-launch-video {
        &:hover {
            .icon {
                box-shadow: 0 1.33px 0.5rem rgba(0,0,0,0.2);
            }
            .label {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .Home {
        height: 100%;
        flex-wrap: wrap;
        padding-top: 0;

        .mainLayout {
            position: fixed;
            top: 0;
            right: 0.75rem;
            left: 0.75rem;
            bottom: 0;    
            padding:0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden !important;
        }
    
        .Text {
            max-width: 30vw;
            margin-left: 5vw;
        }

        &.animate-page-in-state {
            .Text {
                .text-content {
                    opacity: 0;
                    transform: translateY(20vh);
                }
            }
            .selector {
                opacity: 0;
                transform: translateY(20vh);
            }
        }

        .show-launch-video {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 3vh;
            left: 5vw;

            .icon {
                margin-right: 1.5vh;
                width: 5vh;
                height: 5vh;
                background-color: var(--color-red);
                border-radius: 100%;
                border: none;
                outline: none;
                transition: transform 0.5s ease;

                .play-icon {
                    margin-left: 0.5vh;
                    margin-top: 0.2vh;
                    width: 1.8vh;
                    height: 1.8vh;

                    #Play {
                        fill: white;
                    }
                }
            }

            .label {
                margin-top: 0.3vh;
            }
        }

        .button {
            font-weight: bold;
        }
    }
}