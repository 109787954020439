@import '../../styling/variables.scss';

.AboutUs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > .LayoutColumn {
        &:last-child {
            overflow: hidden;
        }
    }

    > .LayoutRow {
        &:last-child {
            overflow: hidden;
        }
    }
}

@media screen and (orientation: portrait) {
    .AboutUs {
        > .LayoutColumn {
            &:first-child {
                padding-top: 4.5rem;
            }
        }
    
        > .LayoutRow {
            &:first-child {
                padding-top: 4.5rem;
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .AboutUs {
        height: 100%;
        flex-wrap: wrap;
    }
}