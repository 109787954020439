.Holding {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position:center;

    img {
      max-width: 90vw;
    }

    img.logo { 
        position: fixed;
        left: 1em;
        top: 1em;
        max-width: 5vw;
    }

    .overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.3);
        z-index: -1;
    }

    .socials {
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 1rem;

        img {
            width: 2rem;
            margin: 0 0.35rem;
        }
    }

    @media (orientation: landscape) {
        img.landscape {
          display: block;
          max-height: 30vh;
        }
        img.portrait {
            display: none;
        }
    }

    @media (orientation: portrait) {
        img.landscape {
          display: none;
        }
        img.portrait {
            display: block;
            max-width: 90vw;
            max-height: 50vh;
        }

    }

    .text-content {
      text-align: center;
    }

    .text-content {
      white-space: pre-line !important;
    }

    .text-content span {
      white-space: pre-line !important;
    }

    @media (max-width:360px) {
      .text-content span {
        white-space: normal !important;
      } 
    }

    .countdown {
      display: table;
      margin: 1em 0;

      .row {
          display:table-row;
      }
    }

    .countdown_value, .countdown_label {
      text-align: center;
      display: table-cell;
    }

    .countdown_value {
      font-weight: bold;
    }

    .countdown_del {
      min-width: 0.5em;
    }

    .countdown_label {
      font-size: 0.7em;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}


