.Header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;

    /* IE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        z-index: initial;
    }

    .Cursor {
        display: flex;
        flex-direction: row;
    }

    .logo {
        width: 4.5rem;
        margin-left: 0.75rem;
        margin-top: 0.75rem;
    }

    .menu {
        margin-right: 0.75rem;
        margin-top: 0.75rem;
        background-color: transparent;
        border: none;
        width: 2rem;
        height: 2rem;
        padding: 0;
        cursor: none;
        z-index: 9;
        outline: none;

        &:active {
            .hamburger {
                filter: drop-shadow(0 1.33px 0.5rem rgba(0,0,0,0.2));

                #HamburgerCircleRed {
                    transform: scale(1);
                }
                #HamburgerIcon {
                    stroke: var(--color-white);
                }
            }
        }

        .hamburger {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            
            #HamburgerCircleRed {
                transform: scale(0);
                transform-origin: center;
                transition: transform 0.25s ease;
            }
            #HamburgerIcon {
                transition: stroke 0.25s ease;
            }
        }
    }
}

@media (hover: hover) {
    .Header .menu {
        &:hover {
            .hamburger {

                #HamburgerCircleRed {
                    transform: scale(1);
                }
                #HamburgerIcon {
                    stroke: var(--color-white);
                }
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .Header {
        .logo {
            height: 6vh;
            width: auto;
            left: 1.8vh;
            top: 1.8vh;
        }

        .menu {
            right: 1.8vh;
            top: 1.8vh;
            width: 4.75vh;
            height: 4.75vh;
        }
    }
}