@import '../../styling/variables.scss';

.SubmitMessage {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(248,239,230,0.9);
    z-index: 3;
    backdrop-filter: blur(0.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: backdrop-filter 0.25s ease, background-color 0.5s ease 0.25s;

    &.animate-element-in-state {
        background-color: transparent;
        backdrop-filter: blur(0);

        .submit-content {
            transform: translateY(10vh);
            opacity: 0;
        }
    }

    &.animate-element-out-state {
        background-color: transparent;
        backdrop-filter: blur(0);
        transition: backdrop-filter 0.25s ease 0.5s, background-color 0.5s ease;

        .submit-content {
            transform: translateY(10vh);
            opacity: 0;
            transition: opacity 0.5s ease, transform 0.5s ease;
        }
    }

    .submit-content {
        width: 24rem;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 2.7rem 3.95rem;
        background-color: #fff;
        border-radius: 0.5rem;
        transition: opacity 0.5s ease 0.25s, transform 0.5s ease 0.25s;

        .submit-title {
            font-size: 1.47rem;
            line-height: 1.6rem;
            margin-bottom: 0.4rem;
        }

        .submit-text {
            font-size: 0.725rem;
            line-height: 0.9rem;
            margin-bottom: 1.35rem;
        }
    }
}

// @media (hover: hover) {
// }

// @media screen and (orientation: landscape) {

// }