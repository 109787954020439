@import '../../styling/variables.scss';

.MasonryGallery {
    width: 100%;

    .gallery-root {
        width: 100%;
        display: flex;

        /* IE */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: -ms-inline-flexbox !important;
        }

        .gallery-group {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 3px;

            .gallery-cell {
                width: 0;
                height: 0;
                margin-bottom: 3px;
                position: relative;
                overflow: hidden;
                background-color: var(--color-greylight);

                &.active {
                    z-index: 1;
                    .gallery-image {
                        transform: scale(1.25);
                    }
                    .gallery-card {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }

                .gallery-image {
                    width: 100%;
                    display: block;
                    transition: transform 0.5s ease;
                }

                .gallery-card {
                    opacity: 0;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.3);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 10px;
                    transition: opacity 0.5s ease;
                    z-index: 1;

                    .gallery-card-content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                    }

                    .gallery-card-name {
                        text-align: center;
                        margin: 1vh 0;
                    }

                    .gallery-card-subname {
                        text-align: center;
                    }

                    .gallery-card-links {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }

            }
        }
    }
}

@media (hover: hover) {
    .MasonryGallery .gallery-root .gallery-group .gallery-cell {
        &:hover {
            z-index: 1;
            .gallery-image {
                transform: scale(1.25);
            }
            .gallery-card {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}

/* IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .MasonryGallery .gallery-root .gallery-group .gallery-cell {
        &:hover {
            z-index: 1;
            .gallery-image {
                transform: scale(1.25);
            }
            .gallery-card {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    .MasonryGallery .gallery-root .gallery-group .gallery-cell .gallery-card .gallery-card-content {
        display: inline-block;
    }
}


@media screen and (orientation: landscape) and (min-height: 1060px) {
    .MasonryGallery {
        .gallery-root .gallery-group .gallery-cell .gallery-card .gallery-card-content {
            .gallery-card-name {
                font-size: 27px;
                line-height: 36px;
            }
            .gallery-card-subname
            .gallery-card-links {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .MasonryGallery {
        &.horizontal {
            width: auto;
            height: 100%;

            .gallery-root {
                width: auto;
                height: 100%;
                display: flex;
                flex-direction: column;
        
                .gallery-group {
                    flex: 1 1 auto;
                    flex-direction: row;
                    height: 100%;
                    margin-right: 0;
                    margin-bottom: 3px;

                    .gallery-cell {
                        width: 0;
                        height: 0;
                        margin-bottom: 0;
                        margin-right: 3px;

                        .gallery-image {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}