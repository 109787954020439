.InfiniteScroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;


    .anchor {
        width: 1px;
        height: 1px;
        display: block;
        margin-bottom: 1rem;
    }
}