.selector {
    position: relative;

    /* Anything but IE! */
    @supports not (-ms-high-contrast: none) {
        width: 9rem;
    }

    &.active {
        .label {
            display: none;
        }
    }

    &:active {
        .label {
            text-decoration: underline;
        }

        .dropdown-icon {
            #CircleRed {
                transform: scale(1);
            }
    
            #Arrow {
                stroke: var(--color-white);
            }
        }
    }

    .label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 0 0.25rem;
        display: flex;
        align-items: center;
        pointer-events: none;

        .name {
            transition: transform 0.25s ease;
        }
    }

        
    /* IE Only */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .dropdown-icon {
            display: none !important;
        }
    }

    /* Anything but IE! */
    @supports not (-ms-high-contrast: none) {
        
        .select {
            width: 100%;
            appearance: none;
            border: none;
            padding-left: 0.25rem;
            background-color: transparent;
            outline: none;
            white-space: break-spaces;
            &.hidden {
                opacity: 0;
            }
        }

        .dropdown-icon {
            width: 1rem;
            margin-left: 0.5rem;
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            #CircleRed {
                transform: scale(0);
                transform-origin: center;
                transition: transform 0.5s ease;
            }

            #Arrow {
                stroke: var(--color-black);
                transition: stroke 0.5s ease;
            }
        }
    }
}

@media (hover: hover) {
    .selector {
        &:hover {
            .label {
                text-decoration: underline;
            }
            .dropdown-icon {
                #CircleRed {
                    transform: scale(1);
                }
        
                #Arrow {
                    stroke: var(--color-white);
                }
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .selector {
        .label {
            .name {
                font-size: 1.955vh;
                line-height: 2.5vh;
            }
        }
        .select {
            font-size: 1.955vh;
            line-height: 2.5vh;
        }
        .dropdown-icon {
            width: 2.75vh;
            height: 2.75vh;
        }
    }
}


.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .form-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1rem;

        .row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .form-label {
            font-size: 0.625rem;
            line-height: 0.85rem;
        }

        .form-input {
            width: 100%;
            font-size: 0.8rem;
            padding: 0.35rem 0.5rem;
            border: 1px solid #E6E8ED;
            border-radius: 0.25rem;
            resize: none;
        }

        .form-emojis {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 0.5rem;

            .form-emoji {
                position: relative;

                .form-emoji-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid transparent;
                    border-radius: 0.5rem;
                    padding: 0.1rem 0.1rem;

                    .emoji-icon {
                        width: 1.4rem;
                        height: 1.4rem;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                .form-emoji-input {
                    position: absolute;
                    left: -999999999999px;
                    visibility: hidden;

                    &:checked {
                        & + .form-emoji-label {
                            border-color: var(--color-red);
                        }
                    }
                }
            }
        }
    }

    .form-options {
        display: flex;
        align-items: center;
        margin-top: 1rem;
    
        .button {
            margin: 0 1rem 0 0;
        }

        .cancel {
            background-color: transparent;
            border: none;
        }
    }
}

@media (hover: hover) {
    .form {
        .form-options {
            .cancel {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}