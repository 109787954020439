.Launch {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: var(--color-beige);

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-around;
    }
    
    .LayoutColumn {
        padding: 0;
        width: 100%;
        height: 100%;
    }
    
    figure {
        margin:0 !important;
    }

    .video-container {
        position: relative;
        width: 100vmin;
    }

    .logo {
        max-height: 10vh;
    }

    .overlay {

        position: absolute;
        z-index: 100;
        background-color: var(--color-greylight);
        background-size: contain;
        
        width: 100.1%;
        height: 100.1%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        user-select: none;

        .start-button {
            height: 20vmin;
            width: 20vmin;
            background-color:  var(--color-red);
            color:  var(--color-white);
            border-radius: 100%;
            border: none;
            outline: none;
            cursor: pointer;
            font-weight: bold;
            font-size: 2vmin;
            padding: 1vmin;
        }

        &.hidden {
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.5s;
        }

    }

    .Text {
        .text-content {
          span {
            white-space: pre-wrap;
          }
        }
        padding: 0 1em;
    }
    
}

