@import '../../styling/variables.scss';

.UserPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media screen and (orientation: portrait) {
    .UserPage {
        > .LayoutColumn {
            &:first-child {
                padding-top: 4.5rem;
            }
        }
    
        > .LayoutRow {
            &:first-child {
                padding-top: 4.5rem;
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .UserPage {
        height: 100%;
        flex-wrap: wrap;
    }
}