@import "../../../styling/variables.scss";

.Title {
    width: 100%;
    height: 8.8rem;
    display: flex;
    align-items: center;
    padding-top: 0.9rem;

    &.long-name {
        .block {
            font-size: 4rem;
        }
    }

    &.theme-black {
        background-color: var(--color-black);
        .block-item {
            color: var(--color-white);
            .block-dash {
                background-color: var(--color-white);
            }
        }
    }

    &.theme-red {
        background-color: var(--color-red);
        .block-item {
            color: var(--color-white);
            .block-dash {
                background-color: var(--color-white);
            }
        }
    }

    @keyframes title-animation {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    .block {
        margin: 0;
        font-size: 5.16rem;
        line-height: 1em;
        white-space: nowrap;

        .block-item {
            display: inline-block;
            animation: title-animation 7s linear infinite;
        }

        .block-dash {
            display: inline-block;
            width: 2.25rem;
            height: 2px;
            margin: 0 0.75rem 0.75rem;
            vertical-align: middle;
        }
    }
}

@media screen and (orientation: landscape) {
    .Title {
        width: 18vw;
        height: 100%;

        &.long-name {
            .block {
                font-size: 7.5vw;
            }
        }

        .block {
            font-size: 10vw;
            transform: translateX(9.8vw) translateY(50vh) rotate(-90deg);
            transform-origin: left center;
        }
    }
}