.CookieBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--color-red);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0.5rem 0.5rem;

    .message {
        font-size: 0.925rem;
        margin-bottom: 0.25rem;
    }

    .buttons {
        display: flex;
    }

    .square-button {
        border: 2px solid var(--color-white);
        cursor: pointer;
        font-size: 0.85rem;
        font-weight: 700;
        margin-left: 0.5rem;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        padding: 0.5rem 0.5rem;
        text-decoration: none;
        transition: background-color 0.25s ease, color 0.25s ease;
        white-space: nowrap;

        &:active, &:visited, &:link {
            color: var(--color-white);
        }
    }
}

@media (hover: hover) {
    .CookieBar {
        .square-button {
            &:hover {
                background-color: var(--color-white);
                color: var(--color-red);
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .CookieBar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .message {
            font-size: 1rem;
        }
    
        .buttons {
            display: flex;

            .square-button {
                margin-bottom: 0.05rem;
                margin-top: 0.05rem;
                padding: 0.5rem 0.5rem;
            }
        }
    }
}