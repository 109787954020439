*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

html,
body {
    position: fixed;
    overflow: hidden;
}

body {
    overscroll-behavior-y: none;
}

#root {
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 16px;
}
 
::-webkit-scrollbar {
    background-color: transparent;
}

::-webkit-scrollbar:vertical {
    width: 6px;
}

::-webkit-scrollbar:horizontal {
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-red);
    border-radius: 16px;
}