@import '../../styling/variables.scss';

.Members {
    width: 100%;
    height: 100%;
    padding: 4.5rem 1rem 1rem;
    display: flex;
    flex-direction: column;

    &.animate-page-in-state {
        .options {
            transform: translateX(5vw);
            opacity: 0;
        }
        .page {
            transform: translateY(20vh);
            opacity: 0;
        }
        .AddressList {
            transform: translateX(20vw);
            opacity: 0;
        }
    }


    .options {
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease, opacity 0.5s ease;

        // Same as search - should use the same styling
        .tags {
            margin: 0;
            padding: 0;
            list-style-type: none;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 0;

            .tag {
                margin: 0.25rem 0.25rem;
                .button {
                    margin-bottom: 0.1rem;
                }
            }
        }

        .nav {
            display: none;
        }
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .list {
            width: 100%;
            position: relative;
            display: flex;
        }
    }

    .page {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1rem;
        transition: transform 0.5s ease, opacity 0.5s ease;
    }

    .message {
        margin-top: 1em;
    }

    /* IE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .message {
            width: 100%;
        }
    }

    .AddressList {
        transition: transform 0.5s ease 0.5s, opacity 0.5s ease 0.5s;
    }
}

@media screen and (orientation: landscape) {
    .Members {
        .options {
            left: 0;
            top: 1rem;
            flex-direction: column;
            width: 100%;
            padding-left: 2rem;
            justify-content: center;

            .tags {
                margin: 0 0 0.25rem;
                width: auto;
                justify-content: center;
                .tag {
                    .button {
                        padding: 0.75rem 1rem;
                        font-size: 0.75rem;
                    }
                }
            }
            .nav {
                display: block;
            }
        }

        .content {
            height: 100%;
            flex-wrap: wrap;
            .page {
                height: 100%;
                width: 30.5rem;
                max-width: 100%;
                padding-right: 6rem;
                padding-left: 2.4rem;
                justify-content: center;

                .title {
                    font-size: 3.75rem;
                    margin-bottom: 0.1rem;
                }
            }
            .list {
                height: 100%;
                padding-top: 5vh;
            }
        }
        .nav {
            display: none;
        }
    }
}

@media screen and (min-width: 850px) {
    .Members {
        .options {
            flex-direction: row;
        }
    }
}