@import "../../../styling/variables.scss";

.MasterclassList {
    display: block;
    width: 100%;
    transition: transform 1s ease 0.25s, opacity 1s ease 0.25s;
    background-color: var(--color-red);
    
    .link {
        text-decoration: none;
        margin-right: 1rem;
        display: flex;

        .number {
            font-size: 0.9rem;
            line-height: 2.5rem;
            flex-shrink: 0;
            width: 1.75rem;
        }

        .name {
            font-size: 1.65rem;
            line-height: 2.5rem;
            color: var(--color-white);
        }
    }
}

.MasterclassList {
    &.animate-component-in-state {
        opacity: 0;
        transform: translateY(20vh);
    }
}

@media (hover: hover) {
    .MasterclassList {
        .link {
            &:hover {
                .name {
                    color: transparent;
                }
            }
            .name {
                -webkit-text-stroke: 2px var(--color-white);
            }
        }
    }
}

/* IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .MasterclassList {
        .link {
            &:hover {
                .name {
                    color: black;
                }
            }
        }
    }
}


// @media screen and (min-width: $width-horizontal-scroll) {
@media screen and (orientation: landscape) {
    .MasterclassList {
        width: auto;
        max-width: 110rem;
        vertical-align: middle;

        .link {
            display: initial;
            .number {
                font-size: 3.235vh;
                line-height: 7.25vh;
                margin-right: 1rem;
            }
            .name {
                font-size: 6.75vh;
                line-height: 7.25vh;
            }
        }
    }

    .MasterclassList {
        &.animate-component-in-state {
        opacity: 0;
        transform: translateX(50vw);
        }
    }
}