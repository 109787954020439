@import '../../../styling/variables.scss';

.Image {
    margin: 0 0 5vh 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 1s ease, opacity 1s ease;

    .asset {
        display: block;
        object-fit: contain;
        object-position: center;
        width: 100%;

        &.asset-xx-small {
            width: 35%;
        }

        &.asset-x-small {
            width: 50%;
        }

        &.asset-small {
            width: 100%;
        }

        &.asset-medium {
            width: 100%;
        }

        &.asset-large {
            width: 100%;
        }

        &.asset-x-large {
            width: 100%;
        }
    }

    .adjacent {
        margin-top: 2vh;
        max-width: 100%;
    }
}

.animate-in-state {
    .Image {
        transform: translateY(20vh);
        opacity: 0;
    }
}

@media screen and (orientation: portrait) {
    .LayoutRow {
        > .AnimateElementIn {
            &:last-child {
                .Image {
                    margin-bottom: 0;
                }
            }
        }
    }

    .LayoutColumn {
        > .AnimateElementIn {
            &:last-child {
                .Image {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .Image {
        margin: 0 5vw 0 0;

        &.adjacent-position-right {
            flex-direction: row;
            .adjacent {
                margin-top: 0;
                margin-left: 2vw;
            }
        }
    
        .asset {
            height: 50vh;
            min-width: 5rem;
    
            &.asset-xx-small {
                height: 7vh;
                width: auto;
            }
    
            &.asset-x-small {
                height: 15vh;
                width: auto;
            }
    
            &.asset-small {
                height: 28vh;
                width: auto;
            }
    
            &.asset-medium {
                height: 43vh;
                width: auto;
            }
    
            &.asset-large {
                height: 60vh;
                width: auto;
            }
    
            &.asset-x-large {
                height: 75vh;
                width: auto;
            }
        }
    
        .adjacent {
            max-width: 14rem;
        }
    }

    .animate-in-state {
        .Image {
            transform: translateX(20vw);
            opacity: 0;
        }
    }

    .LayoutRow {
        > .AnimateElementIn {
            &:last-child {
                .Image {
                    margin-right: 0;
                }
            }
        }
    }

    .LayoutColumn {
        > .AnimateElementIn {
            .Image {
                margin-right: 0;
            }
        }
    }
}