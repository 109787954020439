@import "../../styling/variables.scss";

@keyframes icon-pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.Showcase {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    z-index: 12;

    .gallery-logo {
        width: 4.5rem;
        position: fixed;
        left: 0.75rem;
        top: 0.75rem;
        z-index: 9;
    }

    .gallery-close {
        position: fixed;
        right: 0.75rem;
        top: 0.75rem;
        background-color: transparent;
        border: none;
        width: 2rem;
        height: 2rem;
        padding: 0;
        cursor: none;
        outline: none;
        z-index: 9;
    
        &:active {
            .close-icon {
                filter: drop-shadow(0 1.33px 0.5rem rgba(0,0,0,0.2));
                #CloseCircleRed {
                    transform: scale(1);
                }
                #CloseCross {
                    stroke: var(--color-white);
                }
            }
        }

        .close-icon {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;

            #CloseCircleRed {
                transform: scale(0);
                transform-origin: center;
                transition: transform 0.25s ease;
            }
            #CloseCross {
                transition: stroke 0.25s ease;
            }
        }
    }

    .gallery-loading {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.99;
        background-color: var(--color-white);
        z-index: 5;
    }

    .gallery-container {
        padding-left: 25%;
        height: 100%;
        width: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        overflow-x: scroll;
    }

    .gallery-item {
        height: 100%;
        width: auto;
        margin-bottom: 0;
        margin-right: 5rem;
        position: relative;

        .gallery-image {
            height: 100%;
            width: auto;
            max-width: 90vw;
            min-width: 7.5rem;
            display: block;
            transition: transform 0.1s ease;
            object-position: center;
            object-fit: contain;
        }
    }

    .cta-action {
        position: fixed;
        left: calc(50% - 4.6875rem);
        top: calc(50% - 4.6875rem);
        width: 9.375rem;
        height: 9.375rem;
        border-radius: 100%;
        pointer-events: none;
        z-index: 6;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-red);
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.25s ease, transform 0.25s ease;

        &.active {
            opacity: 1;
            transform: scale(1);
        }

        .cta-text {
            font-size: 1rem;
            font-weight: bold;
            color: var(--color-white);
            text-align: center;
            padding: 0.25rem 0.75rem 0;
            margin: 0;
        }

        .cta-icon {
            width: 1rem;
            animation: icon-pulse 1.5s ease infinite;
        }
    }
}

@media (hover: hover) {
    .Showcase {
        .gallery-close {
            &:hover {
                .close-icon {
                    #CloseCircleRed {
                        transform: scale(1);
                    }
                    #CloseCross {
                        stroke: var(--color-white);
                    }
                }
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .Showcase {
        .gallery-item {
            .gallery-image {
                max-width: none;
                transform-origin: bottom;
            }
        }
    }
}