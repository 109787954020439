@import "../../../styling/variables.scss";

.LayoutRow {
    display: flex;
    flex-direction: column;

    /* Anything but IE! */
    @supports not (-ms-high-contrast: none) {
        width: 100%;
    }
    
    padding: 5vh 5vw;
    position: relative;
    
    &.theme-white {
        background-color: var(--color-white);
        color: var(--color-black);
    }
    &.theme-red {
        background-color: var(--color-red);
        color: var(--color-white);
    }
    &.theme-black {
        background-color: var(--color-black);
        color: var(--color-white);
    }
    &.theme-beige {
        background-color: var(--color-beige);
        color: var(--color-red);
    }
    &.theme-grey {
        background-color: var(--color-grey);
        color: var(--color-black);
    }

    &.has-bg-text {
        z-index: 1;
    }

    .LayoutColumn,
    .LayoutRow {
        height: auto;
        &:not(.has-theme) {
            padding: 0 !important;
        }
        &.has-theme {
            padding: 5vh 5vw;
        }
    }

    .background-text {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding-left: 14.5vw;
        z-index: -1;

        .text-content {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
        }
    }
}

@media screen and (orientation: portrait) {
    .LayoutRow {
        align-items: stretch !important;

        &.theme-white {
            & + .LayoutRow,
            & + .LayoutColumn {
                &.theme-white {
                    padding-top: 0;
                }
            }
        }
        &.theme-red {
            & + .LayoutRow,
            & + .LayoutColumn {
                &.theme-red {
                    padding-top: 0;
                }
            }
        }
        &.theme-black {
            & + .LayoutRow,
            & + .LayoutColumn {
                &.theme-black {
                    padding-top: 0;
                }
            }
        }
        &.theme-beige {
            & + .LayoutRow,
            & + .LayoutColumn {
                &.theme-beige {
                    padding-top: 0;
                }
            }
        }
        &.theme-grey {
            & + .LayoutRow,
            & + .LayoutColumn {
                &.theme-grey {
                    padding-top: 0;
                }
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .LayoutRow {
        flex-direction: row;
        width: max-content;
        height: 100%;
        padding: 12.5vh 5vw;
    }
}