@import '../../styling/variables.scss';

.Search {
    width: 100%;
    height: 100%;
    padding: 4.5rem 1rem 1rem;
    display: flex;
    flex-direction: column;

    &.animate-page-in-state {
        .input {
            transform: translateX(20vw);
            opacity: 0;
        }
        
        .tags {
            .tag {
                transform: translateX(20vw);
                opacity: 0;
            }
        }
        
        .club {
            transform: translateY(10vh);
            opacity: 0;
        }
    }

    .input {
        width: 100%;
        border: none;
        border-bottom: 1px solid #D8D8D8;
        font-size: 2.9rem;
        font-weight: 700;
        padding: 0 0.1rem 0.15rem;
        flex-shrink: 0;
        outline: none;
        transition: transform 0.5s ease, opacity 0.5s ease;

        &::placeholder {
            color: #e8e8e8;
        }

        /* Anything but IE! */
        @supports not (-ms-high-contrast: none) {
            color: #071019;
        }
        
    }

    .tags {
        margin: 0.3rem 0 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;

        .tag {
            margin: 0.25rem 0.05rem;
            transition: transform 0.5s ease, opacity 0.5s ease;

            .button {
                margin: 0;
            }
        }
    }

    .clubs {
        padding-top: 2rem;
        padding-left: 0.8rem;
        display: flex;
        flex-direction: column;

        .no-clubs {
            width: max-content;
        }

        .club {
            margin-bottom: 0.875rem;
            display: block;
            text-decoration: none;
            transition: transform 0.5s ease, opacity 0.5s ease;

            .club-name {
                text-decoration: none;
                color: #898991;
                transition: color 0.25s ease;
            }

            .club-category {
                text-decoration: none;
                color: #898991;
                transition: color 0.25s ease;
            }
        }
    }
}

@media screen and (orientation: portrait) {
    .Search .tags .tag .button {
        margin: 0;
        font-size: 0.825rem;
        padding: 0.75rem 0.5rem 0.65rem;
    }
}

@media (hover: hover) {
    .Search .clubs .club {
        &:hover {
            .club-name {
                color: #000;
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .Search {
        padding: 12vh 6.05% 3vh;

        .input {
            font-size: 9.25vh;
            padding-bottom: 0.025rem;
        }

        .tags {
            margin: 0.5rem 0 0;
            padding: 0 0.35rem;
        }

        .clubs {
            flex-wrap: wrap;
            height: 100%;
            width: min-content;
            padding-top: 7vh;

            .club {
                flex: 0 0 auto;
                margin-right: 9.75vh;
                margin-bottom: 1.75vh;
                width: 45.75vh;
            }
        }
    }
}