@import "../../styling/variables.scss";

.SelfPortraitsVideo {
    max-width: 100%;
    height: calc(100% - 6rem);
    flex: 1 1;
    object-fit: contain;
    object-position: center;
    transition: transform 1s ease, opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 1.25rem 2.5rem;

    .video-asset {
        position: relative;
        max-width: 100%;
        max-height: calc(100vh - 10vh - 6rem - 2.5rem);
        object-fit: contain;
        object-position: center;
        background-color: #000;
    }

    .wrapper {
        position: relative;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &:fullscreen {
            .video-asset {
                width: 100vw !important;
                height: 100vh !important;
            }
            .playback {
                * {
                    cursor: auto;
                }
            }
        }

        .HoverCta {
            height: 100%;
        }
    }

    .playback {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: var(--color-black);
        height: 1.1rem;
        touch-action: none;
        user-select: none;

        .progress {
            flex: 1;
            margin: 0 0.2rem;
            height: 100%;
            display: flex;
            align-items: center;
            
            .progress-bar {
                height: 0.125rem;
                width: 100%;
                background-color: var(--color-grey);
                border-radius: 0.5rem;

                .progress-bar-active {
                    width: 100%;
                    height: 0.125rem;
                    transform-origin: left;
                    background-color: var(--color-red);
                    border-radius: 0.5rem;
                    transform: scaleX(0);
                }
            }
        }

        .counter {
            font-size: 0.6rem;
            margin: 0 0.25rem;
            color: var(--color-white);
            width: 4.3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.05rem 0 0;
            user-select: none;
        }

        .fullscreen {
            width: 0.5rem;
            height: 0.5rem;
            padding: 0;
            margin: 0 0.25rem;
            display: flex;
            align-items: center;
            flex-shrink: 0;

            .fullscreen-icon {
                width: 100%;
                height: 100%;
            }
        }
    }

    .fullscreen-close {
        position: fixed;
        right: 0.75rem;
        top: 0.75rem;
        background-color: transparent;
        border: none;
        width: 2rem;
        height: 2rem;
        padding: 0;
        cursor: none;
        outline: none;
        z-index: 9;
    
        &:active {
            .close-icon {
                filter: drop-shadow(0 1.33px 0.5rem rgba(0,0,0,0.2));
                #CloseCircleRed {
                    transform: scale(1);
                }
                #CloseCross {
                    stroke: var(--color-white);
                }
            }
        }

        .close-icon {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;

            #CloseCircleRed {
                transform: scale(0);
                transform-origin: center;
                transition: transform 0.25s ease;
            }
            #CloseCross {
                transition: stroke 0.25s ease;
            }
        }
    }
}

@media (hover: hover) {
    .SelfPortraitsVideo {
        .fullscreen-close {
            &:hover {
                .close-icon {
                    #CloseCircleRed {
                        transform: scale(1);
                    }
                    #CloseCross {
                        stroke: var(--color-white);
                    }
                }
            }
        }
    }
}