.ImageCrossfade {

    img {
        height: 100vh;
        max-width: 45vw;
        object-fit: contain;
        opacity: 1;
        transition: opacity 0.5s;
        padding: 4rem;

        &.fadeout {
            opacity:0;
        }
    }

    @media screen and (orientation: portrait) {

        display: flex;
        flex-direction: column;
        align-self: center;

        width: 100%;

        img {
            max-width: initial;
            height: auto;
            width: 100%;
            padding: 0;

        }
    }
    
}