.CurrentShow {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: var(--color-black);
    align-items: center;
    justify-content: center;
    text-align: center;
    text-shadow: rgba(255,255,255, 0.25) 1px 1px;

    .Message, .link {
        font-weight: 500;
    }

    .link {
        text-decoration: underline;
    }
}

@media screen and (max-width: 500px) {
    .CurrentShow {
        .Message, .link {
            font-size: 1.5vh;
        }
    }
}