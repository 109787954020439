@import "../../../styling/variables.scss";

.hyperlink {
    position: relative;
    padding-left: 2.5rem;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.275rem;
    text-decoration: none;
    padding-top: 0.15rem;
    color: var(--color-red);
    display: block;
    margin-bottom: 1rem;
    transition: opacity 1s ease, transform 1s ease;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        border: 2px solid var(--color-red);
        transform: translateY(-50%);
    }

    &:after {
        background-color: var(--color-red);
        transform: translateY(-50%) scale(0);
        transition: transform 0.25s ease;
    }

    &:active {
        text-decoration: underline;
        &:after {
            transform: translateY(-50%) scale(1);
        }
    }

    &.club-hyperlink,
    &.next-club-hyperlink {
        padding-left: 0;
        margin-top: 0.75rem;
        &:after,
        &:before {
            content: none;
        }
    }
}

.theme-red,
.theme-black {
    .hyperlink {
        color: var(--color-white);
        &:before,
        &:after {
            border-color: var(--color-white);
        }
        &:after {
            background-color: var(--color-white);
        }
    }
}

.animate-in-state {
    .hyperlink {
        opacity: 0;
        transform: translateY(20vh);
    }
}

/* IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

        .link {
            img {
                cursor: pointer !important;
            }
        }

}

@media (hover: hover) {
    .hyperlink {
        &:hover {
            text-decoration: underline;
            &:after {
                transform: translateY(-50%) scale(1);
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .hyperlink {
        font-size: 1.955vh;
        line-height: 2.5vh;
        padding-top: 0.3vh;
        padding-left: 5.2vh;
        margin-bottom: 0;

        &.club-hyperlink {
            margin-top: 1.5vh;
        }

        &:before,
        &:after {
            width: 4.166vh;
            height: 4.166vh;
        }
    }

    .animate-in-state {
        .hyperlink { 
            opacity: 0;
            transform: translateX(20vw);
        }
    }
}