input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    padding: 3vh 0;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}
  
input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
    width: 100%;
    cursor: none;
    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
}

/* WebKit */
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 4px solid #fff;
    height: 25px;
    width: 25px;
    background: var(--portraits-current);
    cursor: none;
    margin-top: -11px;
    border-radius: 100%;
}

/* Firefox */
input[type=range]::-moz-range-thumb {
    border: 2px solid #fff;
    height: 25px;
    width: 25px;
    background: var(--portraits-current);
    cursor: none;
    margin-top: -5px;
    border-radius: 100%;
}

/* IE */
input[type=range]::-ms-thumb {
    border: 2px solid #fff;
    height: 25px;
    width: 25px;
    background: var(--portraits-current);
    cursor: none;
    margin-top: -5px;
    border-radius: 100%;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: none;
    background: #000000;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: none;
    background: #000000;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: none;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #000000;
}

input[type=range]::-ms-fill-upper {
    background: #000000;
}

.colors-select-red::-webkit-slider-runnable-track {
    background-image: linear-gradient(to right, var(--portraits-red-left), var(--portraits-red-right)) !important;
}
.colors-select-red::-moz-range-track {
    background-image: linear-gradient(to right, var(--portraits-red-left), var(--portraits-red-right)) !important;
}
.colors-select-red::-ms-fill-lower {
    background-image: linear-gradient(to right, var(--portraits-red-left), var(--portraits-red-right)) !important;
}
.colors-select-red::-ms-fill-upper {
    background-image: linear-gradient(to right, var(--portraits-red-left), var(--portraits-red-right)) !important;
}

.colors-select-green::-webkit-slider-runnable-track {
    background-image: linear-gradient(to right, var(--portraits-green-left), var(--portraits-green-right)) !important;
}
.colors-select-green::-moz-range-track {
    background-image: linear-gradient(to right, var(--portraits-green-left), var(--portraits-green-right)) !important;
}
.colors-select-green::-ms-fill-lower {
    background-image: linear-gradient(to right, var(--portraits-green-left), var(--portraits-green-right)) !important;
}
.colors-select-green::-ms-fill-upper {
    background-image: linear-gradient(to right, var(--portraits-green-left), var(--portraits-green-right)) !important;
}

.colors-select-blue::-webkit-slider-runnable-track {
    background-image: linear-gradient(to right, var(--portraits-blue-left), var(--portraits-blue-right)) !important;
}
.colors-select-blue::-moz-range-track {
    background-image: linear-gradient(to right, var(--portraits-blue-left), var(--portraits-blue-right)) !important;
}
.colors-select-blue::-ms-fill-lower {
    background-image: linear-gradient(to right, var(--portraits-blue-left), var(--portraits-blue-right)) !important;
}
.colors-select-blue::-ms-fill-upper {
    background-image: linear-gradient(to right, var(--portraits-blue-left), var(--portraits-blue-right)) !important;
}