@import "../../styling/variables.scss";

.HoverCta {
    width: 100%;
    position: relative;
    cursor: none;

    &.theme-white {
        .icon {
            background-color: var(--color-white);
            #Play,
            #Pause,
            #Gallery {
                stroke: var(--color-red);
            }
        }
        .hover-text {
            color: var(--color-red);
        }
    }

    &.icon-pause {
        .hover-cta {
            width: 5rem;
            height: 5rem;
            display: none;
        }
    }

    &.icon-play {
        .button-icon {
            margin-left: 0.5rem;
        }
    }

    &.icon-text {
        .hover-cta {
            width: 9.375rem;
            height: 9.375rem;
        }
        .hover-text {
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: bold;
            color: var(--color-white);
            text-align: center;
            padding: 0 0.75rem;
            margin: 0;
        }
    }

    .hover-cta {
        width: 6.5rem;
        height: 6.5rem;
        position: absolute;
        left: 50%;
        top: 50%;
        pointer-events: none;
        z-index: 6;
        transition: width 0.25s ease, height 0.25s ease;
        will-change: width, height;
    }
    
    .icon {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: var(--color-red);
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-content {
            width: 22.8%;
        }
    }
}

// If have mouse events
@media (pointer: fine) {
    .HoverCta {
        &.icon-pause {
            .hover-cta {
                display: block;
            }
        }

        .hover-cta {
            left: 0;
            top: 0;
        }
    }
}