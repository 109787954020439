// CSS Variables
:root {
    --color-red: #e30913;
    --color-red2: #c30009;
    --color-blue: #02ABB6;
    --color-green: #BEC32E;
    --color-purple: #674796;
    --color-beige: #F8EFE6;
    --color-black: #071019;
    --color-grey: #898A92;
    --color-greylight: #D7D7D7;
    --color-white: #FFFFFF;

    --portraits-current: rgb(0,0,0);
    --portraits-red-left: rgb(0,0,0);
    --portraits-red-right: rgb(255,0,0);
    --portraits-green-left: rgb(0,0,0);
    --portraits-green-right: rgb(0,255,0);
    --portraits-blue-left: rgb(0,0,0);
    --portraits-blue-right: rgb(0,0,255);
}